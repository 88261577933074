
const cardTemplate = require('../views/includes/stock_item_main.pug');

window.addEventListener('load', () => {

    //анимация для адаптивного меню
    if (document.querySelector('.burger')) {
        var burger = document.querySelector('.burger');
        var lines = document.querySelectorAll('.line');
        var menu = document.querySelector(".navigation")
        burger.addEventListener('click', () => {
            if (burger.classList.contains('burger-active')) {
                burger.classList.remove('burger-active');
                menu.style.display = 'none';
            } else {
                burger.classList.add('burger-active');
                menu.style.display = 'flex';
            }
        })
    }

    //вызов слайдера на странице товара
    if (document.querySelector('.item-slider')) {
        $('.slider').bxSlider({
            adaptiveHeight: 'true'
        });
    }

    //добавление товаров в каталог при большом количестве
    if (document.querySelector('.catalog')) {
        //получаем все карточки товаров
        const items = document.querySelectorAll('.stock_item');
        //скрываем все товары кроме 5 первых
        items.forEach((stock_item, i) => {
            if (i > 4) {
                stock_item.classList.add('hide')

            }
        });

        //получаем кнопку "показать ещё"
        const showmore = document.querySelector('.showMore');
        //функция добавления товаров при клике на кнопку
        function reveal5(e) {
            if (e) {
                e.preventDefault();
            }
            //получаем массив скрытых карточек товаров
            const arrayOfHidden = Array.from(document.querySelectorAll('.stock_item.hide'));


            //добавляем по 5 карточек к видимым
            for (let i = 0; i < 5; i++) {
                try {
                    arrayOfHidden[i].classList.remove('hide')
                } catch (error) {
                    break;
                }
            }

            //скрываем кнопку "показать ещё" если все товары показаны
            if (arrayOfHidden.length < 6) {
                // showmore.removeEventListener('click', reveal5);
                showmore.remove();
            }
        }

        //получаем все скрытые товары
        if (!document.querySelectorAll('.stock_item.hide').length) {
            showmore.remove();
        } else {
            showmore.addEventListener('click', reveal5);
        }
    }

    //вывод случайных товаров на главную страницу
    if (document.querySelector('.main')) {
        let cars;
        fetch('./data.json').then((response) => {
            return response.json()
        })
            .then(data => {
                cars = data;
                cars['FIAT-Kastenwagen'].forEach(item => {
                    item.href = `./fiat-kastenwagen/${item.href}`
                });
                cars['MERCEDES-BENZ-Sprinter-Kastenwagen'].forEach(item => {
                    item.href = `./mercedes-benz-sprinter-kastenwagen/${item.href}`
                });
                var a = Math.floor(Math.random() * (4 + 1));
                var numPool = [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19]
                var b = numPool[Math.floor(Math.random() * numPool.length)];
                document.querySelector('.rigthCard').innerHTML += cardTemplate({ item: cars['FIAT-Kastenwagen'][a] });
                document.querySelector('.rigthCard').innerHTML += cardTemplate({ item: cars['MERCEDES-BENZ-Sprinter-Kastenwagen'][b] });
            })
            .catch(e => console.warn(e));
    }

    //вывод сообщения об отправке формы 
    if (document.querySelector("#send-message")) {
        document.querySelector("#send-message").addEventListener("submit", () => {
            var message = document.querySelector(".message");
            var messageTxt = document.querySelector(".messageTxt");
            var loader = document.querySelector(".loader");
            message.style.display = "flex";
            loader.style.display = 'flex';
            setTimeout(hideLoaderAndShowMessageTxt, 1000);
            setTimeout(hideMessageTxt, 2500);

            function hideLoaderAndShowMessageTxt() {
                loader.style.display = 'none';
                messageTxt.style.display = 'flex';
            }
            function hideMessageTxt() {
                document.querySelector("#nameInput").value = "";
                document.querySelector("#mailInput").value = "";
                document.querySelector("#messageInput").value = "";
                messageTxt.style.display = 'none';
                message.style.display = "none";
            }
        });

    }
})



