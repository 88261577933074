var pug = require("!../../../../../npm/node_modules/pug/node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (item) {pug_html = pug_html + " \u003Cdiv class=\"stock_item_main\"\u003E\u003Ca" + (" class=\"stock_item_main-content\""+pug.attr("href", `/${item.href}.html`, true, true)) + "\u003E\u003Cdiv class=\"stock_item_main-img\"\u003E\u003Cimg" + (pug.attr("src", item.photosSrc + '/0.jpg', true, true)+pug.attr("alt", item.title, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"stock_item_main-description\"\u003E\u003Cp class=\"stock_item_main-title\"\u003E" + (pug.escape(null == (pug_interp = item.title) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003Cdiv class=\"stock_item_main-info\"\u003E";
if (!!item.make) {
pug_html = pug_html + "\u003Cp class=\"stock_item_main-make\"\u003EMarke: \u003Cspan\u003E" + (pug.escape(null == (pug_interp = item.make) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fp\u003E";
}
if (!!item.type) {
pug_html = pug_html + "\u003Cp class=\"stock_item_main-type\"\u003EAufbautyp: \u003Cspan\u003E" + (pug.escape(null == (pug_interp = item.type) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fp\u003E";
}
if (!!item.model) {
pug_html = pug_html + "\u003Cp class=\"stock_item_main-model\"\u003EModellvariante: \u003Cspan\u003E" + (pug.escape(null == (pug_interp = item.model) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fp\u003E";
}
if (!!item.year) {
pug_html = pug_html + "\u003Cp class=\"stock_item_main-year\"\u003EBaujahr: \u003Cspan\u003E" + (pug.escape(null == (pug_interp = item.year) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fp\u003E";
}
if (!!item.mileage) {
pug_html = pug_html + "\u003Cp class=\"stock_item_main-mileage\"\u003EKilometerstand: \u003Cspan\u003E" + (pug.escape(null == (pug_interp = item.mileage) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fp\u003E";
}
if (!!item.power) {
pug_html = pug_html + "\u003Cp class=\"stock_item_main-power\"\u003ELeistung: \u003Cspan\u003E" + (pug.escape(null == (pug_interp = item.power) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fp\u003E";
}
if (!!item.weight) {
pug_html = pug_html + "\u003Cp class=\"stock_item_main-weight\"\u003EGesamtgewicht: \u003Cspan\u003E" + (pug.escape(null == (pug_interp = item.weight) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fp\u003E";
}
if (!!item.price) {
pug_html = pug_html + "\u003Cp class=\"stock_item_main-price\"\u003E Preis: \u003Cspan\u003E" + (pug.escape(null == (pug_interp = item.price) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";}.call(this,"item" in locals_for_with?locals_for_with.item:typeof item!=="undefined"?item:undefined));;return pug_html;};
module.exports = template;